import React, { useMemo } from 'react';
import styled from 'styled-components';
import { FeatureAppLoader } from '@feature-hub/react';

import { getVideoPlayerConfig } from '../utils/getVideoPlayerConfig';
import { AssetTypeVideo, FeatureAppMeta } from '../../types';

const VIDEO_PLAYER_FA_NAME = 'fa-video-player';
const VIDEO_PLAYER_FA_VERSION = 'v5.3.6';
const VIDEO_PLAYER_FA_ENV = 'prod';
const VIDEO_PLAYER_FA_BASE_URL = `https://${VIDEO_PLAYER_FA_NAME}.cdn.${VIDEO_PLAYER_FA_ENV}.collab.apps.one.audi/${VIDEO_PLAYER_FA_VERSION}`;

export interface VideoWrapperProps {
  preserveVideoWidth?: boolean;
}
const VideoWrapper = styled.div<VideoWrapperProps>`
  width: 100%;

  ${(props) => {
    return `
    height: ${props.preserveVideoWidth ? 'auto' : '100%'};
      `;
  }}

  img {
    display: inline !important;
  }
`;

interface VideoProps {
  asset: AssetTypeVideo;
  meta: FeatureAppMeta;
}

export const Video = ({ asset, meta }: VideoProps) => {
  const videoPlayerConfig = useMemo(() => getVideoPlayerConfig(asset), [asset]);

  return (
    <VideoWrapper preserveVideoWidth={asset.preserveVideoWidth}>
      <FeatureAppLoader
        featureAppId={`${meta.id}_${VIDEO_PLAYER_FA_NAME}`}
        featureAppName={VIDEO_PLAYER_FA_NAME}
        config={videoPlayerConfig}
        serverSrc={`${VIDEO_PLAYER_FA_BASE_URL}/fh/app.node.js`}
        src={`${VIDEO_PLAYER_FA_BASE_URL}/fh/app.js`}
      />
    </VideoWrapper>
  );
};
