import { ContentFragment, InvalidContentError, isContentFragment } from '@oneaudi/falcon-tools';
import { BasicTeaserContent, HeadingTag, ThemeColor } from '../../types';

export interface FalconContent extends Partial<ContentFragment> {
  __type: 'aem-headless';
  fields: {
    asset: Partial<ContentFragment> & {
      title: string;
      description: string;
      id: string;
      path: string;
      contentPath: string;
      created: string;
      status: string;
      fields: {
        assetType: 'video' | 'image';
        assetAlt: string;
        assetDisclaimers: string[];
        assetSrc: {
          path: string;
          contentPath: string;
        };
        assetMediaPosition: 'left' | 'right';
        assetAspectRatio: '16:9' | '1:1';
        assetPoster: {
          path: string;
          contentPath: string;
        };
        assetAutoplay: boolean;
        assetLoop: boolean;
        assetMuted: boolean;
        assetControls: boolean;
        assetPreserveVideoWidth: boolean;
      };
    };
    heading: string;
    headingTag: 'h2' | 'h3' | 'h4';
    subHeading: string;
    text: string;
    firstButtonLabel: string;
    firstButtonUrl: string;
    firstButtonVariant: 'primary' | 'secondary';
    firstButtonAriaLabel: string;
    firstButtonOpenInTabOrLayer: 'page' | 'tab' | 'layer' | 'chatbot';
    firstChatBotId?: string;
    secondButtonLabel?: string;
    secondButtonUrl?: string;
    secondButtonVariant?: 'secondary';
    secondButtonAriaLabel?: string;
    secondButtonOpenInTabOrLayer?: 'page' | 'tab' | 'layer' | 'chatbot';
    secondChatBotId?: string;
    themeColor: 'light' | 'dark';
    consumptionAndEmissions: string[];
    disclaimers: string[];
    supplierName?: string;
    supplierLinkLabel?: string;
    supplierLinkUrl?: string;
    supplierLinkAriaLabel?: string;
  };
}

export interface EditorJsonContent extends BasicTeaserContent {
  __type?: 'editor.json';
  asset: {
    assetType: 'video' | 'image';
    src: {
      assetValue: string;
    };
    alt?: string;
    assetDisclaimers: string[];
    mediaPosition?: 'left' | 'right';
    aspectRatio?: '16:9' | '1:1';
    poster?: {
      assetValue: string;
    };
    autoPlay?: boolean;
    loop?: boolean;
    muted?: boolean;
    controls?: boolean;
    preserveVideoWidth?: boolean;
  };
  heading: string;
  headingTag: HeadingTag;
  subHeading: string;
  text: string;
  buttons: {
    label: string;
    url: string;
    variant: 'primary' | 'secondary';
    ariaLabel: string;
    openInTabOrLayer: 'page' | 'tab' | 'layer' | 'chatbot';
    chatBotId?: string;
  }[];
  themeColor: ThemeColor;
  consumptionAndEmissions: string[];
  disclaimers: string[];
  supplier?: {
    supplierName: string;
    supplierLink: {
      label: string;
      url: string;
      ariaLabel: string;
    };
  };
}

function isFalconContent(content: unknown): content is FalconContent {
  return Boolean(isContentFragment(content));
}

const mapContent = (content: EditorJsonContent | FalconContent): EditorJsonContent => {
  if (!content || Object.keys(content).length === 0) {
    // eslint-disable-next-line
    console.warn('No Editor content found for Basic Teaser');

    return {} as EditorJsonContent;
  }

  if (content.__type !== 'aem-headless') {
    return content;
  }
  return convertHeadlessContentToEditorFormat(content);
};

const convertHeadlessContentToEditorFormat = (content: FalconContent): EditorJsonContent => {
  if (!isFalconContent(content)) {
    throw new InvalidContentError('Content is invalid.');
  }

  const { fields } = content;
  const {
    asset,
    heading,
    headingTag,
    subHeading,
    text,
    firstButtonLabel,
    firstButtonUrl,
    firstButtonVariant,
    firstButtonAriaLabel,
    firstButtonOpenInTabOrLayer,
    firstChatBotId,
    secondButtonLabel,
    secondButtonUrl,
    secondButtonVariant,
    secondButtonAriaLabel,
    secondButtonOpenInTabOrLayer,
    secondChatBotId,
    themeColor,
    consumptionAndEmissions,
    disclaimers,
    supplierName,
    supplierLinkLabel,
    supplierLinkUrl,
    supplierLinkAriaLabel,
  } = fields;

  const normalizedContent: EditorJsonContent = {
    asset: {
      assetType: asset?.fields?.assetType,
      src: {
        assetValue: asset?.fields?.assetSrc.path,
      },
      alt: asset?.fields?.assetAlt,
      assetDisclaimers: asset?.fields?.assetDisclaimers,
      mediaPosition: asset?.fields?.assetMediaPosition,
      aspectRatio: asset?.fields?.assetAspectRatio,
      poster: {
        assetValue: asset?.fields?.assetPoster?.path,
      },
      autoPlay: asset?.fields?.assetAutoplay,
      loop: asset?.fields?.assetLoop,
      muted: asset?.fields?.assetMuted,
      controls: asset?.fields?.assetControls,
      preserveVideoWidth: asset?.fields?.assetPreserveVideoWidth,
    },
    heading,
    headingTag,
    subHeading,
    text,
    buttons: [
      {
        label: firstButtonLabel,
        url: firstButtonUrl,
        variant: firstButtonVariant,
        ariaLabel: firstButtonAriaLabel,
        openInTabOrLayer: firstButtonOpenInTabOrLayer,
        chatBotId: firstChatBotId,
      },
      {
        label: secondButtonLabel || '',
        url: secondButtonUrl || '',
        variant: secondButtonVariant || 'secondary',
        ariaLabel: secondButtonAriaLabel || '',
        openInTabOrLayer: secondButtonOpenInTabOrLayer || 'page',
        chatBotId: secondChatBotId,
      },
    ],
    themeColor,
    consumptionAndEmissions,
    disclaimers,
    supplier: {
      supplierName: supplierName || '',
      supplierLink: {
        label: supplierLinkLabel || '',
        url: supplierLinkUrl || '',
        ariaLabel: supplierLinkAriaLabel || '',
      },
    },
  };

  return {
    ...normalizedContent,
  };
};

export default mapContent;
