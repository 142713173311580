import * as React from 'react';
import {
  renderTextWithFootnotesReferencesV2,
  cleanupEmissionConsumptionMarkup,
  getConsumptionsAndEmissions,
} from '@oneaudi/feature-app-utils';
import { Text } from '@audi/audi-ui-react';

import type { BasicTeaserServices } from '../../types';

interface ConsumptionAndEmissionsProps extends BasicTeaserServices {
  identifiers: string[];
  onCaeReady?: () => void;
}

interface CaeData {
  consumption: string | React.ReactNode[];
  emissions: string | React.ReactNode[];
  emissionClass: string | React.ReactNode[];
  dischargedCO2Class?: string | React.ReactNode[];
  dischargedConsumption?: string | React.ReactNode[];
}

export const ConsumptionAndEmissions = ({
  identifiers,
  vueFormatterService,
  localeService,
  onCaeReady,
}: ConsumptionAndEmissionsProps): React.ReactElement | null => {
  const [caeData, setCaeData] = React.useState<CaeData[]>();

  React.useEffect(() => {
    if (vueFormatterService && localeService)
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      (async () => {
        const consumptionsAndEmissionsValues = await getConsumptionsAndEmissions(
          identifiers,
          vueFormatterService,
          localeService,
        );

        const _caeData: CaeData[] = consumptionsAndEmissionsValues
          .filter((cae) => {
            return (
              cae?.formattedConsumption ||
              cae?.formattedEmission ||
              cae?.formattedCo2Class ||
              cae?.formattedDischargedConsumption ||
              cae?.formattedDischargedCo2Class
            );
          })
          .map((cae) => {
            return {
              consumption: cae.formattedConsumption
                ? renderTextWithFootnotesReferencesV2(
                    cleanupEmissionConsumptionMarkup(cae.formattedConsumption),
                  )
                : '',
              emissions: cae.formattedEmission
                ? renderTextWithFootnotesReferencesV2(
                    cleanupEmissionConsumptionMarkup(cae.formattedEmission),
                  )
                : '',
              emissionClass: cae.formattedCo2Class
                ? renderTextWithFootnotesReferencesV2(
                    cleanupEmissionConsumptionMarkup(cae.formattedCo2Class),
                  )
                : '',
              dischargedConsumption: cae.formattedDischargedConsumption
                ? renderTextWithFootnotesReferencesV2(
                    cleanupEmissionConsumptionMarkup(cae.formattedDischargedConsumption),
                  )
                : '',
              dischargedCO2Class: cae.formattedDischargedCo2Class
                ? renderTextWithFootnotesReferencesV2(
                    cleanupEmissionConsumptionMarkup(cae.formattedDischargedCo2Class),
                  )
                : '',
            };
          });
        if (_caeData.length) setCaeData(_caeData);
      })();
  }, []);

  React.useEffect(() => {
    if (caeData?.length) onCaeReady?.();
  }, [caeData?.length]);

  if (!caeData?.length) return null;

  return (
    <>
      {caeData.map((x, i) => (
        <Text
          key={[
            'a',
            i,
            typeof x.emissionClass === 'object'
              ? JSON.stringify(x.emissionClass)
              : x.emissionClass.toString(),
          ].join('_')}
          variant="copy2"
          spaceStackEnd="xs"
        >
          {x.consumption}
          {x.emissions && <span>; {x.emissions}</span>}
          {x.emissionClass && <span>; {x.emissionClass}</span>}
          {x.dischargedConsumption && <span>; {x.dischargedConsumption}</span>}
          {x.dischargedCO2Class && <span>; {x.dischargedCO2Class}</span>}
        </Text>
      ))}
    </>
  );
};
